<template>
    <NuxtLink :to="`/event/${data.slug}`" class="group">
        <div class="relative overflow-hidden">
            <Media v-if="data.media[0]" :data="data.media[0]" class="block h-full mx-auto group-hover:grayscale group-hover:contrast-150 transition duration-200"/>
            <div
                class="aspect-card absolute top-0 w-[150%] h-[150%] bottom-0 left-1/2 -translate-x-2/4 mix-blend-screen opacity-0 group-hover:opacity-100 transition duration-200"
                :class="{'bg-pink': isKinFestival, 'bg-green': !isKinFestival}"
            ></div>
        </div>

        <p class="text-mini mt-4 uppercase">{{data.stage[0].title}}</p>
        <h3 class="headline mt-1">{{data.title}}</h3>
        <p class="headline-serif">{{$dateFormat(data.dateFrom, data.dateTo)}}</p>
    </NuxtLink>
</template>

<script setup>
const { $dateFormat } = useNuxtApp()
const isKinFestival = ref(false)

const props = defineProps({
    data: {
        type: Object,
        default: {
            title: 'Element Card',
            slug: 'lorem-ipsum',
            titleEnglish: 'Element Card',
            dateFrom: "2024-06-04T07:00:00+00:00",
            dateTo: "2024-08-17T07:00:00+00:00",
            stage: [{title: 'Annex scene'}],
            media: [{
                url: 'https://sydhavnteater:8890/assets/Screenshot-2024-07-01-at-14.12.29.jpg',
                width: 1232,
                height: 1232,
                mimeType: 'image/jpeg',
                title: 'Hero image'
            }],
        }
    }
})

if (props.data.category) {
    props.data.category.forEach((item) => {
        if (item.slug == 'kin-festival') {
            isKinFestival.value = true
        }
    })
}
</script>

<style scoped>
:deep(.media) img {
    @apply aspect-card object-cover;
}
</style>